<script setup>
import { computed } from 'vue'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import SelectVoluntaryContribution from '@/components/abTests/stepFour/partials/SelectVoluntaryContribution.vue'
/* if we keep it maybe we could delete VoluntaryContributionStepper */
const props = defineProps({
  realAmount: { type: Number, default: 0 }
})
const emit = defineEmits(['open-explanation-modal'])
const { tipSelectedInEuros } = useVoluntaryContribution()

const openExplanationModal = () => {
  emit('open-explanation-modal')
}

const formattedTip = computed(() => `${tipSelectedInEuros.value} €`)
</script>

<template>
  <div data-testid="consent-user">
    <details>
      <summary>
        <p>
          Votre contribution à HelloAsso <span>({{ formattedTip }})</span>
        </p>
        <fa icon="angle-up" />
      </summary>
      <p>(libre et modifiable)</p>
      <SelectVoluntaryContribution
        :tiers-amount="realAmount"
        @open-explanation-modal="openExplanationModal"
      ></SelectVoluntaryContribution>
    </details>
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable */
details {
  overflow: hidden;
  border: 1px solid var(--ha-color-primary);
  border-radius: $ha-radius-regular;

  &:not([open]) {
    border-color: var(--ha-color-border);
    summary svg {
      transform: rotate(180deg);
      color: var(--ha-color-text-light);
    }
  }

  p {
    margin-left: 1em;
    color: var(--ha-color-midnight);
  }

  summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    list-style: none;
    cursor: pointer;

    p {
      margin-bottom: 0;
      margin-left: 0;
      font-weight: $ha-font-weight-bold;
      font-size: $ha-font-size-big;

      span {
        color: var(--ha-color-primary);
      }
    }

    svg {
      transition: transform 0.2s;
      color: var(--ha-color-primary);
    }
  }
}
</style>
