<template>
  <div class="SummaryForm Step-Summary">
    <div class="Summary StepTransition">
      <span class="Summary--Title">
        {{ $t('campaign.step.summary.titleBill') }}
      </span>
      <section class="SummaryForm--Total">
        <div class="CardSummary">
          <!-- Tickets -->
          <section
            :class="['CardSummary--Section-Tickets', { 'CardSummary--Section': !isCrowdfunding }]"
          >
            <CartSummaryLightTest @edit-discount="onEditDiscount" />
          </section>

          <!-- Reduction -->
          <section
            v-if="displayCouponSection"
            class="CardSummary--Section CardSummary--Section-Reduction"
          >
            <h3 v-show="coupon && !editDiscount" class="CardSummary--Title">
              {{ $t('campaign.step.summary.discount') }}
            </h3>
            <DiscountCodeFieldTest :editDiscount="editDiscount" @applied="onUpdateDiscountCode" />
            <!-- v-show instead of v-if is required to have the following div reactive! -->
            <div v-show="coupon && !editDiscount" class="CardSummary--Coupon">
              <PriceListItem
                :key="`cart-summary-2`"
                :cart-item="{
                  customAmount: discountApplied
                }"
              >
                <div slot="tierName">
                  <div class="Tier-Name-WithIcon">
                    {{ coupon }}
                    <client-only>
                      <ha-button
                        variant="flat"
                        color="danger"
                        size="small"
                        data-testid="modify-discount"
                        @click="editDiscount = true"
                      >
                        <template #icon>
                          <fa icon="pencil-alt" />
                          <span class="visually-hidden">
                            {{ $t('coupon.update') }}
                          </span>
                        </template>
                      </ha-button>
                    </client-only>
                  </div>
                </div>
              </PriceListItem>
            </div>
          </section>
        </div>
      </section>
      <div v-if="canUpdateVoluntaryContribution && (isVariationC || isVariationD)" class="separator"></div>
      <div class="SummaryForm">
        <ContributionSelectionTestB
          v-if="canUpdateVoluntaryContribution && isVariationB"
          :real-amount="realAmount"
          :cart="cartStore"
          @open-explanation-modal="onOpenExplanationModal"
        />

        <ContributionSelectionTestC
          v-if="canUpdateVoluntaryContribution && isVariationC"
          :real-amount="realAmount"
          @open-explanation-modal="onOpenExplanationModal"
        />
        <ContributionSelectionTestD
          v-if="canUpdateVoluntaryContribution && isVariationD"
          :real-amount="realAmount"
          @open-explanation-modal="onOpenExplanationModal"
        />
      </div>
      <template
        v-if="isPaymentTermsEnabled && isFrequenciesAvailable && availableFrequencies?.length"
      >
        <!-- <div class="recap-total-amount">
          <span>{{ $t('cartModalities.totalCart') }}</span>
          <span>{{ totalAmount }}</span>
        </div> -->
        <CartModalities />
      </template>

      <PaymentScheduleStepper
        v-if="cartHasScheduledPayments"
        :payments="cartStore.paymentTerms"
        :tip-selected="tipSelected"
      />
      <HoneyPot :value="honeyPot" @input="handleHoneyPotInput" />
      <ModalContributionExplicationTest
        v-if="displayModalContributionExplication && isABtest"
        @close="closeContributionExplicationModal"
      />
      <ModalModify
        v-if="displayModalContributionSelection"
        :tiers-amount="realAmount"
        :payment-installments-number="cartStore.paymentTerms.length"
        @close="closeContributionEditionModal"
      />
      <div class="SummaryPrice">
        <p>{{ $t('abTasty.summaryPrice') }}</p>
        <p>{{ functions.convertToEuros(finalPrice) }}€</p>
      </div>
    </div>
    <!-- Checkboxes -->
    <div class="SummaryValidation Columns Is-Multiline">
      <LegalCheckboxes :is-tipable="isTipable" />
    </div>
  </div>
</template>

<script setup>
import { filter, forEach, get, sumBy, size } from 'lodash-es'
import { HaButton } from '@ha/components'
import { functions } from '@ha/helpers'
import useFormType from '@/composables/useFormType'

import {
  useStore,
  useContext,
  onMounted,
  useRoute,
  useRouter,
  onBeforeMount
} from '@nuxtjs/composition-api'

import PriceListItem from '@/components/carts/summary/partials/PriceListItem.vue'
import ContributionSelectionTestB from '@/components/abTests/stepFour/ContributionSelectionTestB.vue'
import DiscountCodeFieldTest from '@/components/abTests/stepFour/DiscountCodeFieldTest.vue'
import ModalContributionExplicationTest from '@/components/abTests/stepFour/ModalContributionExplicationTest.vue'
import PaymentScheduleStepper from '@/components/carts/summary/partials/PaymentScheduleStepper.vue'
import ModalModify from '@/components/voluntary-contributions/partials/ModalModify.vue'
import LegalCheckboxes from '@/components/carts/LegalCheckboxes.vue'
import HoneyPot from '@/components/globals/honey-pot/HoneyPot.vue'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import useStoreData from '@/composables/useStoreData'
import useHoneyPot from '@/composables/useHoneyPot'
import useCart from '@/composables/useCart'
import CartModalities from '@/components/carts/CartModalities.vue'
import useModalities from '@/composables/useModalities'
import useAbTasty from '@/composables/useAbTasty'
import useTracking from '~/composables/useTracking'
import { computed, ref, watch } from 'vue'
import CartSummaryLightTest from '@/components/abTests/stepFour/CartSummaryLightTest.vue'
import ContributionSelectionTestD from './ContributionSelectionTestD.vue'
import ContributionSelectionTestC from './ContributionSelectionTestC.vue'

const props = defineProps({
  hasScheduledPayments: Boolean
})

const { isPaymentTermsEnabled, isFrequenciesAvailable, availableFrequencies, currentPaymentTerms } =
  useModalities()

const {
  setTipSuggested,
  tipSelected,
  tipSelectedInEuros,
  isTipable,
  displayModalContributionExplication,
  displayModalContributionSelection,
  openContributionExplicationModal,
  closeContributionEditionModal,
  closeContributionExplicationModal
} = useVoluntaryContribution()
const { form, storeRouteParams } = useStoreData()
const { honeyPot } = useHoneyPot()

const {
  cartStore,
  cartItems,
  cartHasScheduledPayments,
  cartTipData,
  discountApplied,
  STEP_SUMMARY,
  totalCartPrice
} = useCart()
const { isCrowdfunding } = useFormType()
const { i18n, $gtm } = useContext()
const store = useStore()
const route = useRoute()
const router = useRouter()
const { organization } = useStoreData()
const { trackingTrack } = useTracking()
const {
  initAbTasty,
  isVariationB,
  isVariationC,
  isVariationD,
  variationId,
  campaignId,
  isCampaignExecuted
} = useAbTasty()

const editDiscount = ref(true)
const isLoadingTip = ref(true)
const gtmEventHasBeenSent = ref(false)
const isABtest = ref(true)

onBeforeMount(() => {
  initAbTasty('abcdTestFourStepCV')
})

const cartTickets = computed(() => cartItems.value.filter(item => item?.tierType !== 'Donation'))
const cartDonations = computed(() => cartItems.value.filter(item => item?.tierType === 'Donation'))
const hasOnlyFreeTickets = computed(() => sumBy(cartTickets.value, 'customAmount') === 0)
const displayCouponSection = computed(() =>
  get(form.value, 'displayDiscountCode', true) ? !hasOnlyFreeTickets.value : false
)

const options = computed(() => {
  const itemsWithOptions = filter(cartItems.value, function (item) {
    return item.extraOptions
  })
  const options = []
  forEach(itemsWithOptions, item => {
    options.push(
      ...filter(item.extraOptions, option => {
        return option.value
      })
    )
  })
  return options
})

const coupon = computed(() => store.getters['carts/getCartCoupon'](get(this, 'cart.id')))

const realAmount = computed(() => store.getters['carts/getRealAmount'](storeRouteParams.value))
const canUpdateVoluntaryContribution = computed(
  () => !isLoadingTip.value && cartStore.value && realAmount.value > 0
)

const isFreeCart = computed(() => realAmount.value === 0)

const finalPrice = computed(() => {
  if (isPaymentTermsEnabled.value && currentPaymentTerms?.value.length) {
    // @ts-expect-error
    return currentPaymentTerms.value[0].realAmountWithTip
  }
  return realAmount.value + tipSelected.value
})

const isABTestRunning = computed(() => isCampaignExecuted.value ?? false)

const handleHoneyPotInput = $event => {
  setHoneyPotValue($event.target.value)
}

const onEditDiscount = () => {
  editDiscount.value = true
}

const onUpdateDiscountCode = () => {
  editDiscount.value = false
}

const onOpenExplanationModal = () => {
  openContributionExplicationModal()
  trackingTrack('Tips Explanation Clicked', {
    isAbTastyRunning: isABTestRunning.value,
    variationId: variationId?.value,
    campaignId: campaignId?.value
  })
}

onMounted(async () => {
  // do not delete the await, needed because store persisted called after mounted, so no data
  await store.getters['carts/getCart'](storeRouteParams.value)

  if (cartTipData.value.selected != null) {
    tipSelectedInEuros.value = (cartTipData.value.selected / 100).toFixed(2)
  }

  setTipSuggested(
    {
      min: cartTipData.value.min,
      max: cartTipData.value.max,
      default: cartTipData.value.default
    },
    cartTipData.value.selected == null
  )
  isLoadingTip.value = false
})

watch(
  cartItems.value,
  () => {
    if (gtmEventHasBeenSent.value || !cartItems.value?.length) return

    $gtm.push(store.getters['carts/getGtmData'](STEP_SUMMARY, storeRouteParams.value))
    gtmEventHasBeenSent.value = true
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.StepTransition {
  border: $ha-border-regular;
  border-radius: $ha-radius-large;

  .SummaryForm--Total {
    padding-inline: $ha-spacing-tiny;
  }

  .SummaryForm {
    padding: $ha-spacing-tiny;
  }
}
.Step-Summary {
  overflow: hidden;
  background-color: var(--ha-color-white);
  border-radius: $ha-radius-large;
  box-shadow: $ha-box-shadow-close;
  padding: $ha-spacing-medium;

  .Summary {
    position: relative;
    padding: 0;
    padding-bottom: $ha-spacing-jumbo;

    &--Title {
      display: block;
      width: 100%;
      margin-bottom: 0;
      padding: $ha-spacing-tiny;
      font-weight: $ha-font-weight-bold;
      font-size: $ha-font-size-large;
      text-align: left;
      border-bottom: $ha-border-regular;
    }

    &Form {
      margin-bottom: $ha-spacing-large;

      + .Summary--Title {
        margin-top: $ha-spacing-big;
      }
    }
    &Price {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--ha-color-air);
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-block: $ha-spacing-small;
        font-weight: $ha-font-weight-bold;

        &:first-of-type {
          margin-left: $ha-spacing-small;
          color: var(--ha-color-midnight);
        }
        &:last-of-type {
          margin-right: $ha-spacing-small;
          color: var(--ha-color-primary);
          font-size: $ha-font-size-big;
        }
      }
    }
  }
}

.separator {
  width: 95%;
  margin-inline: auto;
  border-top: 1px solid set-alpha('border', 0.5);
}

.CardSummary {
  &--Title {
    margin-bottom: $ha-spacing-small;
    font-weight: $ha-font-weight-bold;
  }

  &--Section {
    &-Reduction {
      .Tier-Name-WithIcon {
        display: inline-flex;
        text-transform: uppercase;

        .HaButton-Small.HaButton-IconOnly {
          margin-left: $ha-spacing-tiny;
          padding: $ha-spacing-mini $ha-spacing-tiny !important;
        }
      }
    }

    &-Total {
      margin-top: $ha-spacing-large;
      padding-top: $ha-spacing-large;
      border-top: $ha-border-width-large dashed set-alpha(border, 0.5);
    }
  }

  .Total {
    &--Main,
    &--Details {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      &Name {
        flex: 1 1 auto;
      }

      &Price {
        flex: 0 0 auto;
        padding-left: $ha-spacing-large;
      }
    }

    &--Main {
      margin-bottom: $ha-spacing-mini;
      font-weight: $ha-font-weight-bold;
      font-size: $ha-font-size-big;
    }
  }

  .PriceListItem {
    + .CardSummary--Title {
      padding-top: $ha-spacing-large;
    }
  }
}
</style>
