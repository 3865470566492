<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { functions } from '@ha/helpers'
import { HaButton, HaFormField } from '@ha/components'
import { cleanInputStringNumber } from '@/helpers/formUtils'
import useStoreData from '@/composables/useStoreData'
import AnimatedIllustration from '@/components/voluntary-contributions/partials/AnimatedIllustration.vue'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import RangeSelector from '@/components/voluntary-contributions/partials/RangeSelector.vue'
import useTracking from '@/composables/useTracking'
import useModalities from '@/composables/useModalities'
import useAbTasty from '@/composables/useAbTasty'

const props = defineProps({
  tiersAmount: Number,
  paymentInstallmentsNumber: [Number, String],
  isIllustrations: {
    type: Boolean,
    default: true
  }
})

const { organization } = useStoreData()
const { i18n } = useContext()
const {
  tipSelectedInEuros,
  tipSuggestedInEuros,
  updateTipSelectedInEuros,
  tipSelected,
  tipSuggested
} = useVoluntaryContribution()

const { initAbTasty, campaignId, isCampaignExecuted, isVariationC, variationId } = useAbTasty()

const { trackingMethods } = useTracking()
const currentTipSelectedInEuros = ref(
  parseFloat(tipSelectedInEuros.value)
    ? parseFloat(tipSelectedInEuros.value).toFixed(2)
    : tipSuggestedInEuros.value.default
)

const { isPaymentTermsEnabled } = useModalities()

const emit = defineEmits(['open-explanation-modal'])

const isTipDisabled = ref(!currentTipSelectedInEuros.value)

const parsedTipSelectedInEuro = computed(() =>
  currentTipSelectedInEuros.value ? parseFloat(currentTipSelectedInEuros.value) : 0
)

const isABTestRunning = computed(() => isCampaignExecuted.value ?? false)

onBeforeMount(() => {
  initAbTasty('abcdTestFourStepCV')
})

const tipTracking = eventName => {
  const tipData = {
    tipSuggestion: tipSuggested.value.default,
    tipSuggestionMax: tipSuggested.value.max,
    tipSuggestionMin: tipSuggested.value.min,
    tipChosen: tipSelected.value
  }
  trackingMethods.onTipsUpdate(eventName, tipData, {
    payment_amount: parseFloat(functions.convertToEuros(props.tiersAmount)).toFixed(2),
    payment_frequency_type: props.paymentInstallmentsNumber,
    contribution_default_amount: parseFloat(
      functions.convertToEuros(tipSuggested.value.default)
    ).toFixed(2),
    contribution_amount: parseFloat(functions.convertToEuros(tipSelected.value)).toFixed(2),
    isAbTastyRunning: isABTestRunning.value,
    variationId: variationId?.value,
    campaignId: campaignId?.value
  })
}
const onSaveAndUpdate = newTipSelected => {
  updateTipSelectedInEuros(newTipSelected, isPaymentTermsEnabled.value)
  currentTipSelectedInEuros.value = parseFloat(tipSelectedInEuros.value).toFixed(2)
  tipTracking('Tips Catch Up')
}
const onSave = () => {
  updateTipSelectedInEuros(parsedTipSelectedInEuro.value, isPaymentTermsEnabled.value)
  tipTracking('Tips Submitted')
}
const onTipUpdate = value => {
  currentTipSelectedInEuros.value = value ? cleanInputStringNumber(value) : ''
}

const openExplanationModal = () => {
  emit('open-explanation-modal')
}

const highDonationThreshold = ref(6)
const midDonationThreshold = ref(2)

const status = computed(() => {
  const parsedTip = parseFloat(currentTipSelectedInEuros.value)
  switch (true) {
    case parsedTip > highDonationThreshold.value:
      return 'highDonation'
    case parsedTip > midDonationThreshold.value && parsedTip <= highDonationThreshold.value:
      return 'midDonation'
    case parsedTip <= midDonationThreshold.value && parsedTip > 0:
      return 'lowDonation'
    case currentTipSelectedInEuros.value === '':
    default:
      return 'noDonation'
  }
})

const showCatchUp = computed(() => {
  return (
    currentTipSelectedInEuros.value &&
    parsedTipSelectedInEuro.value <= 0 &&
    props.tiersAmount >= 10000
  )
})

const catchUpButtonLabel = computed(() =>
  i18n.t('abTasty.stepFour.variationD.catchUpButtonLabel', [bankTransactionCostInEuros.value])
)
const bankTransactionCostInEuros = computed(() => functions.convertToEuros(props.tiersAmount / 100))
const getContent = key =>
  i18n.t(`modal.contributionSelection.newModal.${key}.${status.value}`, [organization.value.name])

const onSubmitTip = value => {
  currentTipSelectedInEuros.value = value ? cleanInputStringNumber(value) : ''
  setTimeout(() => {
    onSave()
  }, 300)
}

watch(currentTipSelectedInEuros, () => {
  isTipDisabled.value = !!currentTipSelectedInEuros.value && parsedTipSelectedInEuro.value <= 0
})
</script>

<template>
  <div :class="['contributionSelected', { 'contributionSelected--variationC': isVariationC }]">
    <AnimatedIllustration
      v-if="isIllustrations"
      :tip-selected="currentTipSelectedInEuros"
      :status="status"
    />
    <p class="contributionSelected__text" v-html="getContent('textImage')"></p>
    <HaFormField class="contributionSelected__field">
      <RangeSelector
        :tip="currentTipSelectedInEuros"
        :tip-suggestions="tipSuggestedInEuros"
        @update:tip="onTipUpdate"
        @submit-tip="onSubmitTip"
      />
    </HaFormField>
    <HaButton
      class="contributionSelected__btnExplication"
      variant="link"
      data-testid="button-cv-explication"
      @click="openExplanationModal"
    >
      {{ $t('modal.contributionSelection.newModal.why') }}
    </HaButton>
      <HaButton v-if="showCatchUp" class="no-donation-button" variant="outline" @click="onSaveAndUpdate(bankTransactionCostInEuros)">{{
        catchUpButtonLabel
      }}</HaButton>
  </div>
</template>

<style lang="scss" scoped>
.contributionSelected {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: $ha-spacing-big;
  font-size: $ha-font-size-small;

  @include mediaQuery(900) {
    width: 70%;
    margin-inline: auto;
  }

  &--variationC {
    padding-inline: 0;
  }

  &__field {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: $ha-spacing-big;

    :deep(.HaFormField--Field) {
      width: 95%;
      margin-inline: auto;

      @include mediaQuery(900) {
        width: 100%;
        margin-inline: 0;
      }

      .progress {
        background: var(--ha-color-primary);
      }

      .range-slider {
        margin-bottom: $ha-spacing-medium;
      }
    }

    :deep(.range-selector__custom-field) {
      flex-direction: column;
      width: 100%;

      @include mediaQuery(600) {
        flex-direction: row;
        justify-content: space-evenly;
        width: 350px;
      }

      .range-selector__custom-field__input {
        width: auto;
        max-width: 50%;

        @include mediaQuery(600) {
          width: 35%;
          max-width: initial;
        }
      }
    }
  }

  &__text {
    align-content: center;
    margin-bottom: $ha-spacing-jumbo;
  }

  &__catch-up {
    margin-top: $ha-spacing-medium;

    p {
      color: var(--ha-color-text);
    }
  }

  button {
    margin-bottom: $ha-spacing-medium;
    margin-inline: 0;
  }
}
</style>
