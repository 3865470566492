<template>
  <div class="Card">
    <h4 v-if="hasTitleSlot" class="Card--Title">
      <slot name="title" />
    </h4>
    <div v-if="hasContentSlot" class="Card--Content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    color: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
    hasContentSlot() {
      return !!this.$slots.content
    }
  }
}
</script>
