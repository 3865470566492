var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"CampaignHeader",class:{ Card: !_vm.isDonation, DonationHeader: _vm.isDonation },attrs:{"data-testid":"campaign-header"}},[_c('div',{staticClass:"CampaignHeader__heading"},[_c('h1',{staticClass:"CampaignHeader--Title",attrs:{"data-testid":"title"}},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2),_vm._v(" "),(_vm.isQuickWinForm && _vm.step === 1)?_c('ClientOnly',[_c('ShareButton')],1):_vm._e()],1),_vm._v(" "),_c('h2',{staticClass:"CampaignHeader--Subtitle"},[_vm._t("subtitle",function(){return [(_vm.organizationName)?[_vm._v("\n        "+_vm._s(_vm.byWording)+"\n        "),_c('ha-button',{attrs:{"href":`/associations/${_vm.metadata.organization}`,"target":"_blank","rel":"noopener noreferrer","variant":"link","data-ux":`Forms_${_vm.metadata.type}_CampaignHeader_OrganismPage`}},[_vm._v("\n          "+_vm._s(_vm.organizationName)+"\n        ")])]:_vm._e()]})],2),_vm._v(" "),(_vm.dateLabel)?_c('span',{class:['CampaignHeader--Date', { 'CampaignHeader--Date--quickWin': _vm.isQuickWinForm }]},[_vm._t("date",function(){return [_c('fa',{attrs:{"icon":"calendar-alt"}}),_vm._v("\n      "+_vm._s(_vm.dateLabel)+"\n    ")]})],2):_vm._e(),_vm._v(" "),_c('client-only',[(_vm.step === 1 && _vm.$device.isMobile && !_vm.isDonation && !_vm.isQuickWinForm)?_c('section',{staticClass:"CampaignHeader--Share"},[(!_vm.isNavigatorShare)?_c('ShareLinks',{attrs:{"with-url-copy":""}}):_vm._e(),_vm._v(" "),(_vm.isNavigatorShare)?_c('button',{staticClass:"CampaignHeader--ShareButton",attrs:{"data-ux":`Forms_${_vm.metadata.type}_CampaignHeader_ShareMobile`},on:{"click":_vm.navigatorShare}},[_c('fa',{attrs:{"icon":"share-alt"}})],1):_vm._e()],1):_vm._e()]),_vm._v(" "),(_vm.description && !_vm.form.invertDescriptions)?_c('div',{ref:"description",class:[
      'CampaignHeader--Description',
      {
        collapsed: _vm.isQuickWinForm && _vm.isDescriptionCollapsed,
        gradient: _vm.isLongDescription
      }
    ]},[_c('span',[_vm._v(_vm._s(_vm.description))]),_vm._v(" "),(_vm.isQuickWinForm)?_c('FormPresentation',{attrs:{"is-inverted-description":_vm.form.invertDescriptions}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isQuickWinForm && _vm.isLongDescription && !_vm.form.invertDescriptions)?_c('button',{staticClass:"collapse-control",attrs:{"type":"button"},on:{"click":function($event){_vm.isDescriptionCollapsed = !_vm.isDescriptionCollapsed}}},[_vm._v("\n      "+_vm._s(_vm.isDescriptionCollapsed ? _vm.$t('campaignHeader.displayMore'): _vm.$t('campaignHeader.displayLess'))+"\n      "),_c('fa',{attrs:{"icon":_vm.isDescriptionCollapsed ? 'chevron-down' : 'chevron-up'}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }